import { window } from '@cplace-frontend-applications/cf-core-lib';
import { environment } from '../environments/environment';
import { NGServiceWorkerManifest } from './ng-service-worker-manifest';
export class FECDNLoader {
  private readonly cdnHost: string = environment.cdnHost;
  private readonly filesToLoad: RegExp[] = environment.filesToLoad;
  private readonly defaultApp = environment.defaultApp;
  private win: Window;
  private deployUrl: string;

  constructor(private window: window) {
    this.win = window;
    const feConfig = this.win._cf_fe20_config_;
    this.deployUrl = '';
    if (feConfig.useCDN) {
      this.deployUrl = `${this.cdnHost}/${feConfig.localDeployUrl}${this.defaultApp}/${
        this.win._cf_fe20_config_.versions[this.defaultApp]
      }`;
    } else {
      this.deployUrl = `${feConfig.localDeployUrl}${this.defaultApp}`;
    }
  }

  public init(): Promise<void> {
    return this.win
      .fetch(`${this.deployUrl}/ngsw.json`)
      .then((resp: Response) => {
        resp
          .json()
          .then((body) => {
            this.loadScripts(this.deployUrl, body);
          })
          .catch(() => {
            console.error('Unable to parse ngsw.json body');
          });
      })
      .catch(() => {
        console.error('Unable to load ngsw.json');
      });
  }

  private loadScripts(host: string, manifest: NGServiceWorkerManifest): void {
    const body: HTMLBodyElement | null = this.win.document.querySelector('body');
    const head: HTMLHeadElement | null = this.win.document.querySelector('head');
    if (body && head) {
      this.filesToLoad.forEach((it: RegExp) => {
        const assets = (manifest.assetGroups || [])[0];
        if (assets) {
          const found: string | undefined = assets.urls.find((i: string) => it.test(i));
          if (found) {
            if (found.endsWith('.js')) {
              const scriptEl: HTMLScriptElement = document.createElement('script');
              scriptEl.src = `${host}${found}`;
              body.appendChild(scriptEl);
            } else if (found.endsWith('.css')) {
              const styleEl: HTMLLinkElement = document.createElement('link');
              styleEl.rel = 'stylesheet';
              styleEl.href = `${host}${found}`;
              head.appendChild(styleEl);
            }
          }
        }
      });
    }
  }
}
